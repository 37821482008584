<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="infoGrupoComissao"
      :loading="loading"
      :paginate="true"
      sort-by="descricao"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Grupo Comissão</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="112"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.descricao`]="{ item }">
        {{ item.descricao | TitleCase }}
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="113"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <v-btn
          icon
          v-can-access="1682"
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" v-can-access="113" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="1682"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalGrupoComissao
      :dialog="dialog"
      :labelBtn="labelBtn"
      :grupoComissao="grupoComissao"
      @close="refresh()"
    />
  </div>
</template>

<script>
import comissao from "@/services/http/comissaoService";
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import ModalGrupoComissao from "@/components/empresa/grupoComissao/ModalGrupoComissao";

export default {
  name: "TabelaGrupoComissao",

  components: {
    BaseTable,
    RegisterBottom,
    ModalGrupoComissao,
    IconBottom
  },

  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      grupoComissao: {},
      headers: [
        { text: "ID", value: "id_grupo_comissao" },
        { text: "Grupo", value: "descricao" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      infoGrupoComissao: []
    };
  },

  methods: {
    async buscaGrupoComissao() {
      this.loading = true;
      const { data } = await comissao()
        .grupoComissao()
        .show({ per_page: -1 });
      this.infoGrupoComissao = data.data;
      this.loading = false;
    },
    editItem(item) {
      this.grupoComissao = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.buscaGrupoComissao();
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          ` Deletar Grupo Comissao`,
          `Deseja deletar Grupo Comissao ${item.descricao} ?`
        );
        comissao()
          .grupoComissao(item.id_grupo_comissao)
          .delete(
            {},
            {
              notification: true,
              menssage: "Grupo Comissao deletada com sucesso!"
            }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Grupo Comissao"
        });
      } finally {
        this.buscaGrupoComissao();
      }
    }
  },

  mounted() {
    this.buscaGrupoComissao();
  }
};
</script>

<style></style>
